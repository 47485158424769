import * as React from "react";
import BoardingFeature from "../../features/boarding/workflow/boarding-workflow.feature";
import DashboardLayout from "../../layouts/dashboard/DashboardLayout";
import BoardingWorkflowItemFeature from "../../features/boarding/workflow/boarding-workflow-item.feature";
import ITask from "../../types/task/task.type";
import styled from "styled-components";
import { getUserOnboarding } from "../../services/task.service";
import CelebrateIcon from "../../assets/svg/icons/fireworks.png";
import {EmptyBox} from "@rocket-desert/shared-ui";
import {useState} from "react";

const Celebrate = styled.div`
  text-align: center;
  color: #7a7a7a;
  cursor: pointer;
`;

const BoardingPage: React.FunctionComponent = () => {
  const [tasks, setTasks] = React.useState<ITask[]>(() => []);  
  const [onBoardingCompleted, setOnBoardingCompleted] = React.useState<boolean>(false);
  const [error, setError] = useState();
  React.useEffect(() => {
    async function getTasks() {
      try {
        const onboarding = await getUserOnboarding();
        const tasks = onboarding.tasks.sort((n1:any,n2:any) => {
          if (n1._dueDate > n2._dueDate) {
              return 1;
          }
      
          if (n1._dueDate < n2._dueDate) {
              return -1;
          }
      
          return 0;
      });
        setTasks(tasks);
      
        setOnBoardingCompleted(onboarding.status === 'COMPLETED'); 

      } 
        catch (e: any) {        
        setError(e.message);
      }
    }
    getTasks();
  },[]);
  
  return (
    <>
      <DashboardLayout>
        {
          onBoardingCompleted ? 
          <Celebrate>
            <h2>Congratulations! Your onboarding has been completed.</h2>
            <img src={CelebrateIcon} alt="Congratulations"></img>            
          </Celebrate>
          : 
            <div>
              <BoardingFeature>
                {!error && tasks.length
                  ? tasks.map((item: any) => {
                      return (
                        <BoardingWorkflowItemFeature key={item.id}
                          id={item.id}
                          title={item.title}
                          description={item.action? item.action.description: null}
                          status={item.taskStatus}
                          dueDate={item.dueDate}
                          type={item.action? item.action.type : null}
                        />
                      );
                    })
                  : <EmptyBox message={"There are no onboarding tasks created for you."} />}
              </BoardingFeature>
            </div>           
        }
        
        
      </DashboardLayout>
    </>
  );
};

export default BoardingPage;
